import MDRouter from "@/service/router";
import Error from "@@/common/Error.vue";
import domain from "@/service/domain";
import MainContent from "@@/home/doubleCols.vue";
import { userDetail, query, personCenter } from "@api/index";
import { mapState } from "vuex";
import { env } from "md-base-tools/env";
export default {
  components: {
    Error,
    MainContent
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifOwner() {
      return this.userId && this.owner && this.owner.id && this.userId == this.owner.id ? true : false;
    },
    ifWxApp() {
      return env.isWxApp();
    }
  },
  data() {
    return {
      isShow: true,
      detail: {},
      owner: {},
      file: {},
      showError: false,
      orderListShow: false,
      message: "",
      op_type: 0,
      nft_num: 0,
      stock_hash: "",
      receive_code: "",
      status: "",
      status_name: "",
      user_info: {},
      orderData: {}
    };
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    // userHeader.backnavOnShow(false);
    document.title = "个人中心";
    await this.getDetail();
    await this.query();
    await this.personCenter();

    // eslint-disable-next-line no-undef
    userHeader.getMdlink({
      type: 'ucenter'
    });
    // eslint-disable-next-line no-undef
    userHeader.openappOnShow(true);
  },
  methods: {
    faqi() {
      location.href = "https://account.modian.com/m/initiate";
    },
    outlogin() {
      // MDUSERTOKEN
      // eslint-disable-next-line no-useless-escape
      var keys = document.cookie.match(/[^ =;]+(?==)/g);
      if (keys) {
        console.log(keys);
        for (var i = keys.length; i--;) {
          document.cookie = keys[i] + "=0;domain=" + domain.suffix + ";path=/;expires=" + new Date(0).toUTCString() + ";max-age=0";
        }
      }
      localStorage.setItem("MDUSERTOKEN", "");
      localStorage.setItem("MDUSERINFO", "");
      location.href = domain.wap + "/";
    },
    cheakId() {
      const url = domain.wap + "/identity/verify";
      MDRouter.Link(url);
    },
    tocart() {
      location.href = `${domain.wap}/product/shopping_cart`;
    },
    toHome() {
      location.href = `${domain.wap}`;
    },
    tocard() {
      location.href = `${domain.wap}/product/coupon`;
    },
    address() {
      location.href = `${domain.wap}/address/address_list`;
    },
    topop() {
      location.href = `${domain.wap}/product/pop/${this.userId}.html`;
    },
    tonft() {
      MDRouter.goDigitalList();
    },
    topeople() {
      MDRouter.goFollow();
    },
    tobay() {
      MDRouter.goSupportProject();
    },
    tolike() {
      MDRouter.goLikePro();
    },
    toOrder(type) {
      if (type == '200') {
        location.href = `${domain.wap}/order/order_list?after=true&nav=${type}`;
      } else {
        location.href = `${domain.wap}/order/order_list?nav=${type}`;
      }
    },
    toEvaluate() {
      location.href = `${domain.wap}/product/evaluate/list`;
    },
    async personCenter() {
      const {
        status,
        data
      } = await personCenter({
        to_user_id: this.userId
      });
      if (status == 0) {
        this.orderData = data.order;
      }
      this.orderListShow = true;
    },
    async query() {
      const {
        status,
        data
      } = await query({});
      if (status == 0) {
        this.nft_num = data.nft_num;
      }
    },
    async getDetail() {
      const {
        data,
        status
      } = await userDetail({
        to_user_id: this.userId,
        user_id: this.userId
      });
      if (status == 0) {
        this.user_info = data.user_info;
      }
      this.$nextTick(() => {
        this.showError = true;
      });
    }
  }
};